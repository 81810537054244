import { useCallback, useContext, useState } from 'react';
import PartnersContext from '../../../context/PartnerListingsProvider';
import OfferingsContext from '../../../context/OfferingsListingsProvider';
import { useTranslation } from 'react-i18next';
import KnightsContext from '../../../context/KnightsListingsProvider';
import EventsContext from '../../../context/EventsListingsProvider';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from 'components/ui/accordion';
import { Checkbox } from 'components/ui/checkbox';
import { Trim } from 'shared/utils';
import { cn } from 'lib/utils';
import { vmWareProducts } from 'shared/constants';

export default function ExpertiseViewMore({
  products,
  marketplace,
  knights,
  events,
  updateFilters,
  currentFilters
}: {
  products: any;
  marketplace: boolean;
  knights: boolean;
  events: boolean;
  updateFilters: (a: string, b: any) => void;
  currentFilters: any;
}) {
  const { setCurrentPageFiltering, clickOnFilter } = useContext(
    marketplace
      ? OfferingsContext
      : knights
      ? KnightsContext
      : events
      ? EventsContext
      : PartnersContext
  );

  const { t } = useTranslation();
  const area = products.level1;

  const selectedExpertises = currentFilters?.expertise || [];
  const [viewMore, setViewMore] = useState(false);

  const getSelectedCountByArea = useCallback(
    (subArea) => {
      const level2Index = products.level2.findIndex(
        (e) => Trim(e.name).toLowerCase() === Trim(subArea).toLowerCase()
      );
      if (level2Index < 0) return '';

      const subAreaProducts = products?.level2[level2Index];
      if (!(subAreaProducts?.level3?.length > 0)) return '';

      const selectedBySubarea = subAreaProducts.level3.filter((sub) =>
        selectedExpertises.some(
          (selected) =>
            Trim(selected).toLowerCase() === Trim(sub.name).toLowerCase()
        )
      );

      const count = selectedBySubarea.length || 0;
      return count ? `(${count})` : '';
    },
    [selectedExpertises]
  );

  const onlyLevel2isRelevant = (level2) => vmWareProducts.includes(level2);

  return (
    <>
      {products?.level2?.map((subarea, idx) => (
        <AccordionItem
          key={`${area}_${subarea.name}_${idx}`}
          value={`${area}_${subarea.name}`}
          className={cn(
            'border-0 border-b border-solid border-[#e3e3e5] text-[#53565a] m-0 p-0 mb-4',
            idx >= 3 ? (viewMore ? 'block' : 'hidden') : 'block'
          )}
        >
          <AccordionTrigger className="pr-0 [&[data-state=open]]:text-[#53565A] [&[data-state=open]]:font-light">
            <span className="w-full max-w-[90%]">
              {onlyLevel2isRelevant(subarea.name) ? (
                <Checkbox
                  key={subarea.name}
                  noPropagation
                  label={
                    <span>
                      {subarea.name}{' '}
                      <span className="ml-2 text-[#2360a0]">
                        {getSelectedCountByArea(subarea.name)}
                      </span>
                    </span>
                  }
                  id={subarea.name}
                  checked={selectedExpertises?.includes(subarea.name)}
                  reverse
                  onCheckedChange={() => {
                    setCurrentPageFiltering(0);
                    updateFilters('expertise', subarea.name);
                    clickOnFilter(`Products - ${subarea.name}`);
                  }}
                />
              ) : (
                <>
                  {subarea.name}
                  <span className="ml-2 text-[#2360a0]">
                    {getSelectedCountByArea(subarea.name)}
                  </span>
                </>
              )}
            </span>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col gap-2 pl-4 pt-2 pb-4">
              {subarea?.level3?.map((value) => (
                <Checkbox
                  key={value.name}
                  label={value.name}
                  id={value.name}
                  disabled={onlyLevel2isRelevant(subarea.name)}
                  checked={
                    onlyLevel2isRelevant(subarea.name)
                      ? selectedExpertises?.includes(subarea.name)
                      : selectedExpertises?.includes(value.name)
                  }
                  reverse
                  onCheckedChange={() => {
                    setCurrentPageFiltering(0);
                    updateFilters('expertise', value.name);
                    clickOnFilter(`Products - ${value.name}`);
                  }}
                />
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      ))}
      {products?.level2.length > 3 && (
        <div
          className="text-[#2360a0] text-sm font-medium cursor-pointer leading-none"
          onClick={() => setViewMore(!viewMore)}
        >
          {viewMore ? `${t('view_less')} -` : `${t('view_more')} +`}
        </div>
      )}
    </>
  );
}
