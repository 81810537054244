import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { cn } from 'lib/utils';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef
} from 'react';
import ModerationButtons from 'components/atoms/ModerationButtons';

interface CheckboxTypes
  extends ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  id: string;
  label?: string | ReactNode;
  labelClassName?: string;
  containerClassName?: string;
  alignItems?: 'start' | 'center' | 'end';
  reverse?: boolean;
  wasChanged?: boolean;
  approved?: boolean;
  dismiss?: boolean;
  changed?: boolean;
  note?: string;
  approve?: boolean;
  required?: boolean;
  noPropagation?: boolean;
  onChangeApprove?: (a?: string, b?: number) => void;
}

const Label = ({
  id,
  label,
  disabled = false,
  labelClassName,
  applyClass,
  required,
  note
}: {
  id: string;
  label: string | ReactNode;
  disabled?: boolean;
  labelClassName: string;
  applyClass: string;
  note?: string;
  required?: boolean;
}) => {
  return (
    <>
      <label
        className={cn(
          'p-0 m-0 cursor-pointer text-sm font-light text-black',
          disabled ? 'opacity-75 cursor-not-allowed' : '',
          applyClass,
          labelClassName
        )}
        htmlFor={id}
      >
        {label}
        {required ? <span className={cn('text-[#CC092F]')}>*</span> : ''}
        {note ? (
          <span className={cn('text-[#97999b]')}> {`(${note})`}</span>
        ) : (
          ''
        )}
      </label>
    </>
  );
};

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxTypes
>((componentProps, ref) => {
  const {
    className,
    id,
    disabled = false,
    label = '',
    required,
    labelClassName = '',
    wasChanged = false,
    approved = false,
    dismiss = false,
    changed = false,
    approve = false,
    reverse = false,
    noPropagation = false,
    note = '',
    onChangeApprove = () => {},
    alignItems = 'center',
    containerClassName = '',
    ...props
  } = componentProps;

  const styles = {
    approved: {
      container: 'border-2 border-solid border-[#6fcf97]',
      label: ''
    },
    dismiss: { container: 'border-2 border-solid border-[#eb5757]', label: '' },
    changed: { container: 'border-2 border-solid border-[#f2c94c]', label: '' },
    requireReview: { container: '', label: '!text-[#f0b51a]' }
  };

  const applyClass = wasChanged
    ? approved
      ? styles.approved
      : dismiss
      ? styles.dismiss
      : changed
      ? styles.changed
      : styles.requireReview
    : approved
    ? styles.approved
    : dismiss
    ? styles.dismiss
    : changed
    ? styles.changed
    : { container: '', label: '' };

  const classNameLabel = cn(labelClassName, reverse ? 'pr-2' : 'pl-2');

  return (
    <div className="flex flex-col gap-2">
      <div
        onClick={(e) => noPropagation && e.stopPropagation()}
        className={cn(
          'grid group',
          reverse ? 'grid-cols-[auto_22px]' : 'grid-cols-[22px_auto]',
          `items-${alignItems}`,
          applyClass.container,
          containerClassName
        )}
      >
        {reverse && label ? (
          <Label
            id={id}
            label={label}
            disabled={disabled}
            labelClassName={classNameLabel}
            applyClass={cn(applyClass.label || '')}
            required={required}
            note={note}
          />
        ) : null}
        <CheckboxPrimitive.Root
          ref={ref}
          id={id}
          disabled={disabled}
          aria-label={`${label}`}
          className={cn(
            'cursor-pointer peer h-5 w-5 p-0 rounded-[2px] border border-solid border-[#e2e3e4] bg-white group-hover:border-gray-300',
            'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            'data-[state=checked]:border-[#CC092F] group-hover:data-[state=checked]:border-[#CC092F] data-[state=checked]:bg-[#CC092F] data-[state=checked]:text-primary-foreground',
            className
          )}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            className={cn(
              'flex items-center justify-center text-current w-full'
            )}
          >
            <Check className="h-4 w-4" strokeWidth={3} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {!reverse && label ? (
          <Label
            id={id}
            label={label}
            disabled={disabled}
            labelClassName={classNameLabel}
            applyClass={applyClass.label || ''}
            required={required}
            note={note}
          />
        ) : null}
      </div>
      {approve && wasChanged && (
        <ModerationButtons
          id={id}
          onChangeApprove={onChangeApprove}
          approved={approved}
          dismissed={dismiss}
        />
      )}
    </div>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
